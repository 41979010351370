<template>
  <div class="kiosk-key-container">
    <div class="kiosk-key kiosk-key-number fs-72 fw-600" v-for="index in 9" :key="index" @click.stop="setup(index, $event)">{{index}}</div>
    <div class="kiosk-key kiosk-key-char fs-72 fw-600" @click.stop="clear('char'); scaleUp($event)">
      <img src="@/assets/icon/backspace.svg" alt="" style="margin-top: -15px" >
    </div>
    <div class="kiosk-key kiosk-key-number fs-72 fw-600" @click.stop="setup(0, $event)">0</div>
    <div class="kiosk-key kiosk-key-char fs-52 fw-600 d-flex" @click.stop="clear('all'); scaleUp($event)">
      <div class="m-auto">
        CLEAR
      </div>
      <!-- <img class="mt-3" src="@/assets/icon/delete.svg" alt=""> -->
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scaleUp (event) {
      const evt = event.target
      evt.classList.add('button-click')
      setTimeout(() => {
        evt.classList.remove('button-click')
      }, 150)
    },
    setup (val, event) {
      this.$emit('set', val)
      this.scaleUp(event)
    },
    clear (item) {
      this.$emit('del', item)
    }
  }
}
</script>

<style>

</style>
