<template>
    <div :class="['divButtonDown', isLeft ? 'left-0': 'right-0']">
        <div class="p-relative">
            <img src="@/assets/icon/chevron-double-down.svg" class="animate-down" :class="!isLeft ? 'animate-down-left': 'animate-down-right'">
        <button @click="emit" class="btn-scan fs-52 fw-700" :class="isLeft ? 'btn-scan-radius-left': 'btn-scan-radius-right'">{{textInfo}}</button>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data () {
    return {
      isTimeOut: '',
      tempText: '',
      resultText: ''
    }
  },
  props: {
    isListenQr: {
      type: Boolean,
      default: false
    },
    isLeft: {
      type: Boolean,
      default: false
    },
    textInfo: {
      type: String,
      default: ''
    }
  },
  unmounted () {
    // eslint-disable-next-line no-undef
    // document.removeEventListener('keyup', this.checkKeyUp)
  },
  mounted () {
    // if (this.isListenQr) {
    //   document.addEventListener('keyup', this.checkKeyUp)
    // } else {
    //   document.removeEventListener('keyup', this.checkKeyUp)
    // }
  },
  computed: {
    ...mapState({
      listenQR: state => state.isSCanQR
    })
  },
  watch: {
    listenQR (val) {
      if (val) {
        document.addEventListener('keyup', this.checkKeyUp)
      } else {
        document.removeEventListener('keyup', this.checkKeyUp)
      }
    }
  },
  methods: {
    emit () {
      this.emitter.emit('change-component')
    },
    ...mapActions({
      removeDescription: 'DocumentHandler/TOGGLE_DESC_LABEL'
    }),
    checkKeyUp (e) {
      const textInput = e.key || String.fromCharCode(e.keyCode)
      const targetName = e.target.localName
      if (e.keyCode === 13) {
        clearTimeout(this.isTimeOut)
        this.isTimeOut = setTimeout(() => {
          this.resultText = this.tempText
          this.tempText = ''
          if (this.resultText === 'go-to-setting') {
            this.$router.push({ name: 'setting' })
          } else if (this.resultText === 'show-hide-label-description') {
            this.removeDescription()
          } else {
            // toast untuk hasil scan
            this.$toast.show(`${this.resultText}`, {
              type: 'info',
              position: 'bottom-left',
              duration: 5000,
              maxToasts: 4
            })
          }
        }, 100)
      }
      if (textInput && textInput.length === 1 && targetName !== 'input') {
        this.tempText += textInput
      }
    }
  }
}
</script>
