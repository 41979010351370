<template>
<div class="container-kiosk" id="docs">
    <div class="container-body-kiosk">
        <p class="fs-52 fw-700 c-deep mb-40 line-height-1 pr-1">{{$l.detectLanguage('scanQRAnda')}}
          <span class="not-success pr-2"> {{$l.detectLanguage('atau')}} </span>
          <span class="fw-400" v-html="$l.detectLanguage('masukkan kode booking')" ></span>
        </p>
        <div>
            <input type="text" class="input-qr-code fw-600 text-center" ref="input" id="test-inputs" autocomplete="off">
        </div>
        <input-keypad @set="changeData" @del="changeElement" id="text-indo"/>
        <div class="row mt-30">
            <div class="col-6 text-left">
                <button-linear-cancel :data-time-out="config.delay"  @cancel="backToMenu" ref="button-cancel" :id-btn="'btn-cancel-input-keypad'"/>
            </div>
            <div class="col-6 text-right">
                <button class="btn-lanjutkan btn-block fs-42 fw-600" @click="nextAction" :disabled="isDisabled.length < 2">{{$l.detectLanguage('proceed')}}</button>
            </div>
        </div>
    </div>
</div>
 <info-animation-down :text-info="$l.detectLanguage('scanQR')" :listen-qr="false" />
</template>

<script>
import InputKeypad from '@/components/Menu/InputKeyPad'
import InfoAnimationDown from '@/components/Button/InfoAnimationDown'
import ButtonLinearCancel from '@/components/Button/ButtonLinearCancel'
import { useSelector } from '@xstate/vue'
// import Modal from '@/components/Modal'
export default {
  components: {
    InputKeypad,
    InfoAnimationDown,
    ButtonLinearCancel
    // Modal
  },
  mounted () {
    document.getElementById('text-indo').addEventListener('mousedown', event => { event.preventDefault(); event.stopPropagation() })
    document.getElementById('test-inputs').addEventListener('keyup', (event) => {
      this.positionLast = event.target.selectionStart
    })
    document.addEventListener('click', this.reset)
  },
  beforeUnmount () {
    document.removeEventListener('click', this.reset)
  },
  data () {
    const infoDetailConfig = (state) => {
      return state.context.config
    }
    const keyInput = (state) => {
      return state.context.key ?? ''
    }
    const infoConfig = useSelector(window.machineKiosk, infoDetailConfig)
    const valueInput = useSelector(window.machineKiosk, keyInput)
    return {
      config: infoConfig,
      positionLast: 0,
      isDisabled: valueInput,
      dataTimeOut: {
        buttonLinearCancelDelay: 1,
        buttonLinearCancelProgress: 5,
        buttonLinearCancelAfter: 1
      }
      // modalActive: true
    }
  },
  methods: {
    reset (event) {
      event.preventDefault()
      this.$refs['button-cancel'].resetBackgroundLinearButton()
      window.machineKiosk.send({
        type: 'click:anywhere'
      })
    },
    backToMenu () {
      // this.$router.push({ name: 'Menu' })
      window.machineKiosk.send({
        type: 'button:batalkan'
      })
    },
    resetTime (key) {
      window.machineKiosk.send({
        type: 'click:keypad',
        data: {
          key
        }
      })
    },
    // afterClick () {
    //   this.modalActive = false
    // },
    changeData (val) {
      this.$refs['button-cancel'].resetBackgroundLinearButton()
      this.$refs.input.dispatchEvent(new KeyboardEvent('keyup', { key: val }))
      const ab = this.$refs.input.value
      const finalString = [ab.slice(0, this.positionLast), val, ab.slice(this.positionLast)].join('')
      this.$refs.input.value = finalString
      this.resetTime(finalString.toString())
      this.$refs.input.setSelectionRange(this.positionLast + 1, this.positionLast + 1)
    },
    changeElement (item) {
      this.$refs['button-cancel'].resetBackgroundLinearButton()
      if (item === 'all') {
        this.$refs.input.value = ''
        this.resetTime('')
      } else {
        this.$refs.input.dispatchEvent(new KeyboardEvent('keyup', { key: 'Backspace' }))
        const data = this.$refs.input.value
        const finalString = [data.slice(0, this.positionLast - 1), data.slice(this.positionLast)].join('')
        this.$refs.input.value = finalString
        this.resetTime(this.$refs.input.value.toString())
        this.$refs.input.setSelectionRange(this.positionLast - 1, this.positionLast - 1)
      }
    },
    nextAction () {
      window.machineKiosk.send({
        type: 'button:lanjutkan'
      })
      // const data = this.$refs.input.value.replaceAll('-', '')
      // if (data === '081372727272') {
      //   this.$router.push({ name: 'showTicketWithTelepon' })
      // } else {
      //   this.$router.push({ name: 'showTicket', query: { id: data, type: 'booking' } })
      // }
    }
  }
}
</script>

<style>

</style>
