<template>
     <button v-if="!openModal" :class="classButton" style=" animation-timing-function: ease-in;" :id="idBtn" @click.stop="cancel">
        {{$l.detectLanguage('batalkan')}}
    </button>
    <button v-else :class="classButton">
        {{$l.detectLanguage('batalkan')}}
    </button>
</template>
<script>
// import { mapState } from 'vuex'
export default {
  data () {
    return {
      timeOutIdle: '',
      timeOutBackground: ''
    }
  },
  props: {
    classButton: {
      type: String,
      default: 'btn-batalkan btn-block  fs-42 fw-600'
    },
    openModal: {
      type: Boolean,
      default: false
    },
    idBtn: {
      type: String,
      default: 'btn-cancel-linear'
    },
    dataTimeOut: {
      type: Object,
      default: () => {
        return {
          buttonLinearCancelDelay: 1,
          buttonLinearCancelProgress: 5,
          buttonLinearCancelAfter: 1
        }
      }
    }
  },
  computed: {
    // ...mapState({
    //   dataTimeOut: state => state.dataTimeOut
    // })
  },
  mounted () {
    if (!this.openModal) {
      this.runBackgroundLinearButton()
    }
  },
  watch: {
    openModal (val) {
      if (!val) {
        setTimeout(() => {
          this.runBackgroundLinearButton()
        }, 100)
      }
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel', true)
    },
    resetBackgroundLinearButton () {
      const elButton = document.getElementById(this.idBtn)
      if (elButton.classList.contains('linear-background')) {
        elButton.style.animation = 'none'
        elButton.classList.toggle('linear-background')
      }
      this.runBackgroundLinearButton()
    },
    runBackgroundLinearButton () {
      const { buttonLinearCancelDelay, buttonLinearCancelProgress, buttonLinearCancelAfter } = this.dataTimeOut
      const elButton = document.getElementById(this.idBtn)
      if (this.timeOutBackground) clearTimeout(this.timeOutBackground)
      if (this.timeOutIdle) clearTimeout(this.timeOutIdle)
      this.timeOutIdle = setTimeout(() => {
        elButton.style.animation = `toastProgress ${buttonLinearCancelProgress}s steps(1000)`
        elButton.classList.toggle('linear-background')
      }, (parseInt(buttonLinearCancelDelay) * 1000) - 200)
      const totalTime = parseInt(buttonLinearCancelDelay) + parseInt(buttonLinearCancelProgress) + parseInt(buttonLinearCancelAfter)
      this.timeOutBackground = setTimeout(() => {
        this.cancel()
      }, totalTime * 1000)
    }
  }
}
</script>
<style>

</style>
